<template>
  <div class="settings_manufacturing_tests">
    <b-breadcrumb :items="breadcrumb" class="my-3"></b-breadcrumb>

    <b-nav tabs class="mb-3">
      <b-nav-item :active="tab=='pipelines'" v-on:click="tab='pipelines'">Pipelines</b-nav-item>
      <b-nav-item v-if="loadedPipeline && tab=='uploads'" :active="tab=='uploads'" v-on:click="tab='uploads'">Uploads</b-nav-item>
    </b-nav>

    <pipelines v-if="tab=='pipelines'" @uploads="onViewUploads"></pipelines>
    <uploads v-if="tab=='uploads'" :pipeline="loadedPipeline"></uploads>

  </div>
</template>

<script>
import pipelines from '@/components/manufacturing.settings/tests.pipelines'
import uploads from '@/components/manufacturing.settings/tests.uploads'

export default {
  name: 'Pipelines',
  components: {
      pipelines,
      uploads
  },
  data () {
    return {
      tab: '',
      breadcrumb: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'Settings',
          to: '/settings'
        },
        {
          text: 'Manufacturing',
          to: '/settings/manufacturing'
        },
        {
          text: 'Tests',
          active: true
        }
      ],
      loadedPipeline: null
    }
  },
  watch: {
    tab() {
      if(this.tab == 'pipelines') {
        this.loadedPipeline = null
      }
    }
  },
  methods: {
    onViewUploads(data) {
      this.tab = 'uploads'
      this.loadedPipeline = data
    }
  },
  async mounted() {
    this.tab = 'pipelines'
  }
}
</script>
