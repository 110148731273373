<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <b-button-group>
          <b-button variant="outline-primary" v-on:click="loadData"><b-icon-arrow-repeat></b-icon-arrow-repeat></b-button>
        </b-button-group>
      </div>
      <div class="col-lg-4 col-md-12">
        <b-form v-on:submit.prevent="loadData">
          <b-input-group>
            <b-input type="text" ref="search" placeholder="Search..." v-model="search" disabled/>
            <b-input-group-append>
              <b-button size="sm" text="Button" variant="outline-primary" v-on:click="loadData" disabled><b-icon-search></b-icon-search></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-table id="main-table" striped hover :items="items" :fields="fields" :busy="isBusy" :tbody-tr-class="rowClass">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(status)="row">
            <b-link v-on:click="showResult(row.item)">
              <span class="badge badge-primary mr-1" v-if="row.item.resultid != '00000000-0000-0000-0000-000000000000'">RESULT <b-icon-search></b-icon-search></span>
            </b-link>
            <b-link v-on:click="showRawData(row.item)">
              <span class="badge badge-primary mr-1">RAW <b-icon-pencil-square></b-icon-pencil-square></span>
            </b-link>
            <!---<span class="badge badge-success" v-if="!row.item.errors">SUCCESS</span>
            <span class="badge badge-danger" v-if="row.item.errors">ERRORS</span>--->
          </template>
          <template #cell(uploadtime)="row">
            {{ row.item.uploadtime | moment('YYYY-MM-DD HH:mm:ss') }}
          </template>
          <template #cell(worker)="row">
            <b-icon-exclamation-triangle-fill variant="danger" v-if="row.item.errors"></b-icon-exclamation-triangle-fill> 
            <b-icon-check-circle-fill variant="success" v-if="!row.item.errors"></b-icon-check-circle-fill>
            {{ row.item.worker.name }}
          </template>
          <template #cell(size)="row">
            {{ (row.item.payloadsize / 1024).toFixed(2) }} kb
          </template>
        </b-table>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span>
        Showing {{ ((currentPage - 1) * currentPageSize) + 1 }} to {{ activeRows() }} of {{ totalItems }} rows 
        <b-dropdown variant="outline-primary" :text="(currentPageSize).toString()" v-model="currentPageSize">
          <b-dropdown-item v-on:click="currentPageSize=10">10</b-dropdown-item>
          <b-dropdown-item v-on:click="currentPageSize=20">20</b-dropdown-item>
          <b-dropdown-item v-on:click="currentPageSize=50">50</b-dropdown-item>
          <b-dropdown-item v-on:click="currentPageSize=100">100</b-dropdown-item>
        </b-dropdown>
        rows per page</span>
      </div>
      <div class="col">
        <b-pagination align="right" v-if="!isBusy" v-model="currentPage" :per-page="currentPageSize" :total-rows="totalItems" aria-controls="main-table"></b-pagination>
      </div>
    </div>

    <b-modal id="correctUploadModal" title="Raw Upload" size="lg" hide-footer>
      <b-alert variant="danger" v-if="(currentItem ? currentItem.message : null)" show>{{ currentItem.message }}</b-alert>
      <b-alert variant="success" v-if="!(currentItem ? currentItem.message : null)" show>Imported successfully!</b-alert>
      <b-form-textarea
        id="textarea"
        v-model="currentPayload"
        placeholder="Upload payload..."
        rows="6"
        max-rows="18"
        style="font-family: Courier New, Lucida Console, Monaco; white-space: nowrap; font-size: 0.80rem"
      ></b-form-textarea>
      <b-button-group class="mt-3 float-left">
        <b-button variant="outline-danger" @click="importCurrent" disabled><b-icon-file-earmark-plus></b-icon-file-earmark-plus> {{ currentItem ? (currentItem.resultid == '00000000-0000-0000-0000-000000000000' ? 'Import' : 'Re-Import') : 'None'}}</b-button>
        <b-button variant="outline-danger" @click="importCurrent" disabled><b-icon-trash></b-icon-trash> Delete</b-button>
      </b-button-group>
      <b-button class="mt-3 float-right" variant="primary" @click="$bvModal.hide('correctUploadModal')"><b-icon-x-circle></b-icon-x-circle> Close</b-button>
    </b-modal>

    <b-modal id="showResult" title="Result JSON" size="lg" hide-footer>
      <div class="json_code rounded p-2">
        <vue-json-pretty :data="currentResult"></vue-json-pretty>
      </div>
      <b-button class="mt-3 float-right" variant="primary" @click="$bvModal.hide('showResult')"><b-icon-x-circle></b-icon-x-circle> Close</b-button>
    </b-modal>

  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import '@/assets/json_code.css';
import { testsService } from '@/services';
import { tablePagination } from '@/mixins/table-pagination'

export default {
  name: 'PipelinesTable',
  props: [
    'pipeline'
  ],
  components: {
    VueJsonPretty,
  },
  mixins: [
    tablePagination
  ],
  data () {
    return {
      fields: ['worker','uploadtime',{key: 'size',label: 'Size'},{key: 'Status', label: '', tdClass: 'text-right'}],
      isBusy: false,
      search: '',
      currentItem: null,
      currentPayload: '',
      currentResult: {},
    }
  },
  watch: {
    currentPage() {
      this.loadData()
    },
    currentPageSize() {
      this.loadData()
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.errors) return 'table-danger'
    },
    loadData() {
      this.isBusy = true
      testsService.getAllUploads(this.pipeline.id, this.search, this.currentPageSize, this.currentPage)
        .then(data => {
          this.totalItems = data.count
          this.items = data.data
        })
        .catch(error => {
          this.$bvToast.toast(`${error}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        })
        .then(() => {
          this.isBusy = false
          this.$refs.search.$el.focus()
        })
    },
    showResult(item) {
      this.currentResult = {'status': 'loading...'}
      testsService.getResultById(this.pipeline.id, item.resultid)
        .then(data => {
          this.currentResult = data
          this.$bvModal.show('showResult')
        })
        .catch(error => {
          this.$bvToast.toast(`${error}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        })
    },
    showRawData(item) {
      this.currentItem = item;
      this.currentPayload = 'Loading...'
      testsService.getUploadById(this.pipeline.id, item.id)
        .then(response => {
          let json = JSON.parse(response.payload)
          let data = null;
          switch(json.type) {
            case "text": {
              data = json.data
              if(json.base64) data = atob(data)
              break;
            }
            case "json": {
              // try - catch errors when parsing json
              data = JSON.stringify(JSON.parse(json.data), null, 2);
              break;
            }
          }
          this.currentPayload = data
          this.$bvModal.show('correctUploadModal')

        })
        .catch(error => {
          this.$bvToast.toast(`${error}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        })
    },
    importCurrent() {
      console.log("not implemented")
    }
  },
  async mounted() {
    this.loadData()
  }
}
</script>

<style scoped>
body {
  text-align: left !important;
}
.link {
  cursor: pointer;
}
.json_code {
  background: rgb(12, 12, 12);
}
</style>